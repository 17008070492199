@use './config/' as *;
@forward './main.scss';

.waf-talk-faq {
    @extend %page-head-blue-bg;
    @extend %accordion-common;
    &.waf-component {
        padding-block: 0 var(--space-20);
        @extend %mb-0;
    }
    .waf-head {
        .head-wrap {
            margin-block: 13rem 16rem;
        }
    }
    .waf-body {
        margin-top: var(--space-15-neg);
        @extend %bg-transparent;
    }
    .tab {
        &s {
            flex-wrap: wrap;
            @extend %flex-c-n;
            @extend %gap-2;
            @extend %p-6-4;
            @extend %border-radius-r;
            @extend %bg-neutral-0;
        }
        &-name {
            cursor: pointer;
            width: 4.8rem;
            height: 4.8rem;
            @include border(1,neutral-20);
            @extend %border-radius-s;
            &.tab-disable {
                pointer-events: none;
                .btn-text {
                    color: var(--neutral-40);
                }
            }
        }
        &-text {
            @extend %w-100;
            @extend %h-100;
            @extend %flex-c-c;
            .btn-text {
                @extend %focus-0;
                @extend %body-mb;
            }
        }
        &-container {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-section {
            @extend %gap-14;
            @extend %flex-column;
        }
        &-container-wrap {
            @extend %border-radius-r;
            @extend %bg-neutral-0;
        }
    }
    .card {
        @extend %p-6-4;
        &-title {
            @extend %title-20;
            @extend %mb-8;
            @extend %neutral-100;
        }
        &-list {
            @extend %gap-6;
            @extend %flex-column;
        }
        &-item {
            @extend %w-100;
            .title {
                @extend %body-mb;
                @extend %mb-4;
            }
            .text {
                @extend %neutral-100;
                @extend %body-m;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-talk-faq {
        .waf-head {
            .head-wrap {
                margin-block: 23.3rem 15rem;
            }
        }
        .tab {
            &s {
                width: 30%;
                height: max-content;
                position: sticky;
                top: 4rem;
            }
            &-section {
                flex-direction: row;
                gap: var(--space-6);            
            }
            &-container {
                width: 70%;
            }
        }
        .card {
            &-list {
                @include card-count(2,var(--space-6),wrap);
            }
            &-item {
                .title {
                    font-size: 2rem;
                }
            }
        } 
    }
}
@include mq(col-lg) {
    .waf-talk-faq {
        .tab {
            &s {
                padding-inline: var(--space-5);
            }
        }
        .card {
            padding: var(--space-12);
            &-list {
                @include card-count(3,var(--space-6),wrap);
            }
        }
    }
}